import React from 'react';
import ReactDOM from 'react-dom/client';
import { createHashRouter, RouterProvider} from 'react-router-dom';
import './index.css';
import { DefaultPage } from './routes/default';
import { ContactPage } from './routes/contact';
import { AboutPage } from './routes/about';
import { PricingPage } from './routes/pricing';
import { ErrorPage } from './components/error-page';
import reportWebVitals from './reportWebVitals';

const router = createHashRouter([
    {
        path: '/',
        element: <DefaultPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: 'about',
        element: <AboutPage />,
    },
    {
        path: 'pricing',
        element: <PricingPage />
    },
    {
        path: 'contact',
        element: <ContactPage />,
    }
]);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
