import { Footer, Navigation } from '../components';

export const DefaultPage = () => {
    return (
        <>
            <Navigation />
            <div className="container-fluid">
                <div className="row hero">
                    <img src="https://placehold.co/1350x450" className="img-fluid" alt="hero" />
                </div>
                <div className="row">
                    <div className="col">col</div>
                </div>
                <div className="row">
                    <div className="col">col</div>
                    <div className="col">col</div>
                </div>
                <div className="row">
                    <div className="col-8">col-8</div>
                    <div className="col-4">col-4</div>
                </div>
            </div>
            <Footer />
        </>
    );
}