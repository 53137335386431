import { useLocation} from "react-router-dom";
import { navigationPaths } from '../constants.js';

export const Navigation = () => {
    const { pathname } = useLocation();

    return(
        <nav className="navbar navbar-expand-lg bg-body-tertiary">
            <div className="container-fluid">
                <a className="navbar-brand" href="/">BraceAndBracket</a>
                <button className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarNavAltMarkup"
                        aria-controls="navbarNavAltMarkup"
                        aria-expanded="false"
                        aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                    <div className="navbar-nav">
                        <a
                            className={`nav-link ${pathname === navigationPaths.about ? 'active' : ''}`}
                            href="#/about">About</a>
                        <a
                            className={`nav-link ${pathname === navigationPaths.pricing? 'active' : ''}`}
                            href="#/pricing">Pricing</a>
                        <a
                            className={`nav-link ${pathname === navigationPaths.contact ? 'active' : ''}`}
                            href="#/contact">Contact</a>
                    </div>
                </div>
            </div>
        </nav>
    );
}