import { Footer, Navigation } from "../components";

export const PricingPage = () => {

    return (
        <>
            <Navigation />
            <div className="container-fluid">
                <h1>pricing</h1>
            </div>
            <Footer />
        </>
    )
}