import { useEffect } from 'react';
import { Footer, Navigation } from "../components";

export const ContactPage = () => {
    useEffect(() => {
      const script = document.createElement('script');

      script.src = "https://assets.calendly.com/assets/external/widget.js";
      script.async = true;

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      }
    }, []);

    return (
        <>
            <Navigation />
            <div className="container-fluid">
                <h1>Contact</h1>
                <div
                    className="calendly-inline-widget"
                    data-url="https://calendly.com/braceandbracket/30min?primary_color=008aa7"
                    style={{height:700+'px',minWidth:350+'px'}}></div>
            </div>
            <Footer />
        </>
    )
}