import { Footer, Navigation} from "../components";

export const AboutPage = () => {
    return (
        <>
            <Navigation />
            <div className="container-fluid">
                <h1>About</h1>
            </div>
            <Footer />
        </>
    )
}